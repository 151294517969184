import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { browser, version } from '$app/environment';
import { navigating } from '$app/stores';

/** @type {ApplicationInsights | null} */
let appInsights = null;
let currentUser = null;

export const track = {
	/** @type {typeof appInsights['trackPageView']} */
	pageView(telemetry) {
		telemetry.properties = {
			...telemetry.properties,
			user: currentUser,
		};
		if (appInsights === null) {
			console.log('[track:pageView] %o', telemetry);
			return;
		}
		return appInsights.trackPageView(telemetry);
	},

	/** @type {typeof appInsights['trackEvent']} */
	event(telemetry) {
		telemetry.properties = {
			...telemetry.properties,
			user: currentUser,
		};
		if (appInsights === null) {
			console.log('[track:event] %o', telemetry);
			return;
		}
		return appInsights.trackEvent(telemetry);
	},

	/** @type {typeof appInsights['trackException']} */
	exception(telemetry, customProperties) {
		customProperties = {
			...customProperties,
			user: currentUser,
		};
		if (appInsights === null) {
			console.log('[track:exception] %o %o', telemetry, customProperties);
			return;
		}
		return appInsights.trackException(telemetry, customProperties);
	},
	/** @param {import('@ctm/auth').UserInfo | null} user */
	user(user = null) {
		if (user) {
			// TODO: appInsights.setAuthenticatedUserContext(user.id);
			currentUser = {
				debtor: user.debtor,
				role: user.roleTypeName,
				roles: user.roles,
			};
		} else {
			// TODO: appInsights.clearAuthenticatedUserContext();
			currentUser = null;
		}
	},
};

export function setupAppInsights() {
	if (!browser) {
		return;
	}
	const env = environmentSettings;
	const { connectionString, maxAjaxCallsPerView } = env.appInsights ?? {};
	if (!connectionString) {
		console.warn('[AppInsights] no connection string in environment settings');
		return;
	}

	const apiUrl = new URL(env.apiUrl);
	appInsights = globalThis.appInsights = new ApplicationInsights({
		config: {
			connectionString,
			/* ...Other Configuration Options... */
			correlationHeaderDomains: [apiUrl.hostname],
			correlationHeaderExcludedDomains: ['*.au.auth0.com'],
			distributedTracingMode: DistributedTracingModes.W3C,
			enableCorsCorrelation: true,
			enableUnhandledPromiseRejectionTracking: true,
			maxAjaxCallsPerView,
		},
	});
	appInsights.loadAppInsights();
	appInsights.context.application.ver = version;
	appInsights.context.application.build = version;
	appInsights.addTelemetryInitializer((envelope) => {
		envelope.tags['ai.cloud.role'] = 'CTM.UserManagement.UI';
	});

	navigating.subscribe((navigation) => {
		if (!navigation) {
			return;
		}
		const { from, to, type } = navigation;
		track.pageView({
			uri: to?.url.href,
			refUri: from?.url.href,
			properties: {
				routeId: to?.route.id,
				params: to?.params,
				type,
			},
		});
	});
}
